import React, { useState } from "react";
import { graphql } from "gatsby";
import { Layout, SEO, DefaultHeader, Footer } from "components";
import styled from "styled-components";
import { P, H3, Content, Container } from "../mixins";

export const query = graphql`
  query TermsPageQuery {
    banner: file(relativePath: { eq: "assets/images/terms-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ({ data }) => {
  const banner = (data || {}).banner;
  return (
    <Layout>
      <SEO title="Terms" description="LHS Terms and conditions" />
      <DefaultHeader
        title="Terms and Conditions"
        description="View all of our terms and conditions below."
        imageFluid={banner}
      />
      <Container>
        <Content>
          <H3>Terms And Conditions</H3>
          <P>
            LHS Terms and conditions go here.</P>
        </Content>
      </Container>
      <Footer/>
    </Layout>
  );
};
